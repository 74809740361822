import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import DataTableControls from './Controls';
import DataTable from 'components/common/DataTable';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { GridRowId } from '@mui/x-data-grid';
import { Columns } from './Controls/Columns';
import { SelectedFilter } from 'components/common/FilterWithDropdown/types';
import { Data, PaginationModel } from 'components/common/DataTable/types';
import { listParticipantGoals } from 'api/organisations/participants/goals';
import useUserOrganisations from 'hooks/useUserOrganisations';
import useInvalidateData from 'hooks/useInvalidateData';
import { useNavigate, useParams } from 'react-router-dom';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';
import { paginationModelDefault } from 'utils/helpers';


const ParticipantGoalsDataTable: FC = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilter[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(paginationModelDefault);
  const [organisations] = useUserOrganisations();
  useInvalidateData('participant-goals');
  const navigate = useNavigate();
  const { id } = useParams();

  const handleNew = () => {
    navigate(`/participant-centre/participants/view/${id}/goals/create`);
  };

  const { isError, data, isPending, isFetching, isLoading } = useQuery({
    queryKey: ['participant-goals', paginationModel, selectedFilters],
    queryFn: () => listParticipantGoals(
      organisations[0].organisation.globalId,
      id as string,
      selectedFilters,
      paginationModel.page + 1,
      paginationModel.pageSize
    ),
    placeholderData: keepPreviousData,
    enabled: organisations.length > 0,
    refetchOnWindowFocus: false,
    staleTime: cachingInvalidation.participantGoals,
  });

  return (
    <Stack height='100%' width='100%' sx={{ backgroundColor: 'white' }}>
      <DataTableControls
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        searchText={searchText}
        setSearchText={setSearchText}
        selectedRowIds={selectedRowIds}
      />
      <DataTable
        data={data as Data}
        columns={Columns}
        setSelectedRowIds={setSelectedRowIds}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        isPending={isPending || isFetching || isLoading}
        isError={isError}
        title='Participant Goals'
        dataTableName='participant-goals'
        checkboxSelection={false}
        paginationMode='server'
        onNoData={handleNew}
      />
    </Stack>
  );
};

export default ParticipantGoalsDataTable;