import React, { forwardRef, useEffect, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { Typography } from '@mui/material';
import { LookupSelectFieldOptions, WrapperLookupFieldProps } from '../types';
import LookupInputField from '..';
import { getRoleTemplateList } from 'api/organisations/users';
import { UserRole } from 'types/dbSchema/userRoles';
import { cachingInvalidation } from 'utils/config/cachingInvalidation';

const RoleTemplateLookupField = forwardRef<HTMLDivElement, WrapperLookupFieldProps>(({
  id,
  label,
  onChange,
  errorText,
  helperText,
  isMandatory,
  placeholder,
  validationError,
  filterValues,
  filterIncludeExclude,
  value
}, ref) => {
  const [organisations] = useUserOrganisations();
  const [searchText, setSearchText] = useState('');
  const [options, setOptions] = useState<LookupSelectFieldOptions[]>([]);

  // useInfiniteQuery is a hook that allows you to fetch data on scroll
  // This function handles the fetching of role templates in the organisation
  const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
    queryKey: ['role-templates-infinite', organisations[0]?.organisation.globalId, 100],
    queryFn: () => getRoleTemplateList(organisations[0].organisation.globalId, null), // No pagination required for this endpoint for MVP
    initialPageParam: 0,
    staleTime: cachingInvalidation.roleTemplates,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage, allPages) => {
      const totalLoadedItems = allPages.flatMap(page => page.items).length;
      if (totalLoadedItems >= lastPage.totalCount) {
        return undefined; // No more pages to load
      }
      return allPages.length;
    },
  });

  // When the data is fetched, map the role templates to the options
  // The label is JSX so that we can customise the look of the options
  useEffect(() => {
    if (data) {
      const newOptions = data.pages.flatMap(page =>
        page.items.filter((role: UserRole) => filterIncludeExclude === 'include' ? filterValues?.includes(role.id) : !filterValues?.includes(role.id))
          .map((role: UserRole) => ({
            value: role.id,
            label: (
              <Typography key={role.id} variant='body2'>
                {role.name}
              </Typography>
            ),
          }))
      );
      setOptions(newOptions);
    }
  }, [data]);

  return (
    <LookupInputField
      id={id}
      ref={ref}
      label={label}
      options={options}
      errorText={errorText}
      placeholder={placeholder}
      helperText={helperText}
      searchValue={searchText}
      isMandatory={isMandatory}
      hasNextPage={hasNextPage}
      dataFetchingError={error}
      fetchNextPage={fetchNextPage}
      setSearchValue={setSearchText}
      validationError={validationError}
      isFetchingNextPage={isFetchingNextPage}
      isFetching={isFetching || status === 'pending'}
      onChange={(value: string) => onChange(value)}
      value={value}
    />
  );
});

RoleTemplateLookupField.displayName = 'RoleTemplateLookupField';

export default RoleTemplateLookupField;