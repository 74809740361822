import React, { FC } from 'react';
import TitleWithClose from 'components/common/TitleWithClose';
import { Button, Stack, Typography } from '@mui/material';
import variables from 'styles/variables';
import styled from 'styled-components';
import { useAtom } from 'jotai';
import { userMessageAtom } from 'atoms';
import { useForm, Controller } from 'react-hook-form';
import { GridRowId } from '@mui/x-data-grid';
import { closeModalAtom } from 'atoms/modalAtom';
import UserPositionsLookupField from 'components/common/LookupInputField/UserPositionLookupField';
import useErrorMessage from 'hooks/useErrorMessage';
import { bulkUpdateUserField } from 'api/organisations/users';
import useUserOrganisations from 'hooks/useUserOrganisations';
import { invalidateDataAtom } from 'atoms/invalidateDataAtom';

export interface UpdatePositionProps {
  selectedRowIds: GridRowId[];
}

const UpdatePosition: FC<UpdatePositionProps> = ({ selectedRowIds }) => {
  const [, setUserMessage] = useAtom(userMessageAtom);
  const showError = useErrorMessage();
  const [, closeModal] = useAtom(closeModalAtom);
  const [organisations] = useUserOrganisations();
  const [, setInvalidateData] = useAtom(invalidateDataAtom);
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      position: ''
    }
  });

  const handleUpdatePosition = async (data: { position: string }) => {
    try {
      await bulkUpdateUserField(organisations[0].organisation.globalId, {
        fieldName: 'OrganisationPosition',
        newValue: data.position,
        userProfileIDs: selectedRowIds as string[]
      });
      setInvalidateData((prev) => ({ ...prev, 'user-profile': true }));
      closeModal();
      setUserMessage({
        title: `${selectedRowIds.length} User${selectedRowIds.length > 1 ? 's' : ''} Updated.`,
        message: `The selected user${selectedRowIds.length > 1 ? 's' : ''} have had their positions updated.`,
        variant: 'success',
        open: true,
        autoHideDuration: 6000,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      });
    } catch (error) {
      showError((error as Error).message);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpdatePosition)}>
      <Stack alignItems="center" width='500px'>
        <Stack width='100%' alignItems="flex-start" gap='32px' padding='32px' boxSizing='border-box'>
          <TitleWithClose title="Update Position" handleClose={closeModal} />
          <Stack gap='8px' width='100%'>
            <Controller
              name="position"
              control={control}
              rules={{ required: 'Position is required' }}
              render={({ field }) =>
                <UserPositionsLookupField
                  {...field}
                  id='position'
                  label='Position'
                  validationError={errors.position ? true : false}
                  errorText={errors.position?.message}
                  isMandatory
                  placeholder='Select'
                />
              }
            />
            <Typography variant='body2' color={variables.colors.text.primary}>
              You are about to update the position of {selectedRowIds.length} user{selectedRowIds.length > 1 ? 's' : ''}.
            </Typography>
          </Stack>
          <ButtonStack>
            <Button variant='outlined' onClick={closeModal}>Cancel</Button>
            <Button type='submit' variant='contained'>Confirm</Button>
          </ButtonStack>
        </Stack>
      </Stack>
    </form>
  );
};

const ButtonStack = styled(Stack)`
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  box-sizing: border-box;
`;

export default UpdatePosition;